@media only screen and (min-width: 1200px) and (max-width: 1920px) {
  .single-about {
    height: 340px;
  }
}

.single-about {
  padding: 30px 20px;
  margin: 11px 0;
  border-radius: 8px;
  transition: all .4s;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
}

.single-about:hover {
  background-color: var(--color-primary);
  color: #fff;
}

.single-about:hover h3 {
  color: #fff;
  border-color: #fff;
}

.single-about .about_img img {
  height: 60px;
  margin-bottom: .5rem;
}

.single-about h3 {
  color: var(--color-default);
  font-weight: 700;
  margin: 0 0 20px 0;
  padding-bottom: 8px;
  font-size: 22px;
  position: relative;
  display: inline-block;
  border-bottom: 4px solid #eeeeee;
  transition: all 0.5s;
  text-transform: capitalize;
}

.single-about p {
  line-height: 24px;
  font-size: 16px;
  margin-bottom: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-about {
    height: 400px;
  }
}

@media only screen and (min-width: 767px) and (max-width: 991px) {
  .single-about {
    height: 380px;
  }
}
@media screen and (max-width: 767px) {
  .single-about {
    background-color: var(--color-primary);
    color: #fff;
  }
  .single-about h3 {
    color: #fff;
    border-color: #fff;
  }
}