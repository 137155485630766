.services {
    padding: 60px 0;
}

.services .service-item {
    padding: 40px;
    background: #fff;
    height: 100%;
    border-radius: 10px;
}

.services .service-item .icon {
    width: 80px;
    height: 80px;
    position: relative;
    margin-bottom: 50px;
}

.services .service-item .icon img {
    color: var(--color-default);
    height: 70px;
    transition: ease-in-out 0.3s;
    z-index: 2;
    position: relative;
}

.services .service-item .icon:before {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    background: #eeeeee;
    border-radius: 50px;
    z-index: 1;
    top: 10px;
    right: -20px;
    transition: 0.3s;
}

.services .service-item h4 {
    color: var(--color-default);
    font-weight: 700;
    margin: 0 0 20px 0;
    padding-bottom: 8px;
    font-size: 22px;
    position: relative;
    display: inline-block;
    border-bottom: 4px solid #eeeeee;
    transition: 0.3s;
    text-transform:  capitalize;
}

.services .service-item p {
    line-height: 24px;
    font-size: 16px;
    margin-bottom: 0;
}



.services .service-item:hover .icon:before {
    background: #00b6a1;
}

.services .service-item:hover h4 {
    border-color: var(--color-primary);
}
@media screen and (max-width: 767px) {
    .services .service-item .icon:before {
        background: #00b6a1;
    }
    
    .services .service-item h4 {
        border-color: var(--color-primary);
    }
}