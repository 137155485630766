
.testimonials .testimonial-wrap {
    padding-left: 10px;
}

.testimonials .testimonial-item {
    box-sizing: content-box;
    padding: 30px;
    margin: 30px 10px;
    box-shadow: 0px 0 15px rgba(0, 0, 0, 0.1);
    position: relative;
    background: #fff;
    border-radius: 10px;
}

.testimonials .testimonial-item .testimonial-img {
    width: 90px;
    border-radius: 50px;
    margin-right: 15px;
}

.testimonials .testimonial-item h3 {
    font-size: 18px;
    font-weight: bold;
    margin: 10px 0 5px 0;
    color: #000;
}

.testimonials .testimonial-item h4 {
    font-size: 14px;
    color: #999;
    margin: 0;
}

.testimonials .testimonial-item .stars {
    margin: 10px 0;
}

.testimonials .testimonial-item .stars i {
    color: #ffc107;
    margin: 0 1px;
}

.testimonials .testimonial-item .quote-icon-left,
.testimonials .testimonial-item .quote-icon-right {
    color: #009d8b;
    font-size: 26px;
    line-height: 0;
}

.testimonials .testimonial-item .quote-icon-left {
    display: inline-block;
    left: -5px;
    position: relative;
}

.testimonials .testimonial-item .quote-icon-right {
    display: inline-block;
    right: -5px;
    position: relative;
    top: 10px;
    transform: scale(-1, -1);
}

.testimonials .testimonial-item p {
    font-style: italic;
    margin: 15px auto 15px auto;
}


@media (max-width: 767px) {
    .testimonials .testimonial-wrap {
        padding-left: 0;
    }

    .testimonials .testimonial-item {
        padding: 30px;
        margin: 15px;
    }

    .testimonials .testimonial-item .testimonial-img {
        position: static;
        left: auto;
    }
}
.fa-quote-left{
    font-size: 26px;
    margin-right: 1rem;
    color: var(--color-primary);
}
.fa-quote-right{
    font-size: 26px;
    margin-left: 1rem;
    color: var(--color-primary);
}
@media only screen and (min-width: 1400px) and (max-width: 1920px) {
    .testimonials .testimonial-item {
        height: 230px;
    }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .testimonials .testimonial-item {
        height: 290px;
    }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .testimonials .testimonial-item {
        height: 360px;
    }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .testimonials .testimonial-item {
        height: 320px;
    }
}