.pricing .pricing-item {
    padding: 30px;
    box-shadow: 0 3px 20px -2px rgba(108, 117, 125, 0.15);
    background: #fff;
    height: 100%;
    position: relative;
    border-radius: 15px;
}
.serial{
    height: 40px;
    width: 40px;
    text-align: center;
    background: var(--color-primary);
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    line-height: 40px;
    position: absolute;
    top: -10px;
    right: 0;
    border-radius: 50%;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px;
    text-shadow: 1px 1px 2px pink;
}
.pricing h4 {
    font-weight: 600;
    margin-bottom: 15px;
    font-size: 20px;
    color: #555555;
    text-align: center;
}

.pricing .icon {
    margin: 30px auto 30px auto;
    width: 70px;
    height: 70px;
    background: var(--color-primary);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s;
    transform-style: preserve-3d;
}

.pricing .icon svg {
    color: #fff;
    transition: ease-in-out 0.3s;
    line-height: 0;
}

.pricing .icon::before {
    position: absolute;
    content: "";
    height: 86px;
    width: 86px;
    border-radius: 50%;
    background: #87c1bb;
    transition: all 0.3s ease-out 0s;
    transform: translateZ(-1px);
}

.pricing .icon::after {
    position: absolute;
    content: "";
    height: 102px;
    width: 102px;
    border-radius: 50%;
    background: #d2eeeb;
    transition: all 0.3s ease-out 0s;
    transform: translateZ(-2px);
}