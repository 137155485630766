.about-five-tab nav {
    border: none;
    background: #dfe1e1;
    padding: 15px;
    border-radius: 5px
}

.about-five-tab nav .nav-tabs {
    border: none;
}

.about-five-tab nav button {
    width: calc(100%/3.19);
    border: none;
    color: #2d2d2d;
    font-weight: 600;
    padding: 0;
    margin-right: 20px;
    position: relative;
    background-color: #fff;
    padding: 10px 18px;
    border-radius: 4px;
    text-transform: capitalize
}

@media(max-width:991px) {
    .about-five-tab nav button {
        margin: 1rem 0;
        width: 100%;
    }

    .about-five-tab nav button:first-child {
        margin-top: 0;
    }
    .about-five-tab nav button:last-child {
        margin-bottom: 0;
    }
}

.about-five-tab nav button:hover {
    color: #009786;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    background-color: #009786;
    color: #fff;
    border-color: #009786;
}

.about-five-tab nav button:last-child {
    margin-right: 0
}

.about-five-tab .tab-content {
    margin-top: 1rem;
    padding:  2rem;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}

.about-five-tab .tab-content p {
    margin-bottom: 20px
}

.about-five-tab .tab-content p:last-child {
    margin: 0
}
@media(max-width: 480px) {
    .p-5 {
        padding: 1rem !important;
    }
    .about-five-tab .tab-content {
        padding: 1rem;
    }
}