.contact .info-container {
    background-color: var(--color-primary);
    height: 100%;
    padding: 20px;
    border-radius: 10px 10px;
    box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.1);
}

.contact .info-item {
    width: 100%;
    background-color: #009282;
    padding: 20px;
    border-radius: 10px;
    color: #fff;
}

@media screen and (max-width: 991px) {
    .contact .info-item {
        margin-bottom: 20px;
    }

    .contact .info-container {
        padding-bottom: 0px;
    }
}


.contact .info-item i {
    font-size: 20px;
    color: #fff;
    float: left;
    width: 44px;
    height: 44px;
    background-color: rgba(255, 255, 255, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    transition: all 0.3s ease-in-out;
    margin-right: 15px;
}

.contact .info-item h4 {
    padding: 0;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 5px;
}

.contact .info-item p {
    padding: 0;
    margin-bottom: 0;
    font-size: 14px;
}
.contact .info-item p a{
    
    color: #fff;
}
.contact .info-item:hover i {
    background: #fff;
    color: var(--color-primary);
}

.contact .php-email-form {
    width: 100%;
    height: 100%;
    background: #fff;
    box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.1);
    padding: 30px;
    border-radius: 0 10px 10px 0;
}